<template>
  <div>
    <b-modal
      id="menu-modal"
      ref="menu-modal"
      title="Tạo thực đơn mới"
      hide-footer
      centered
      @hidden="cancel"
    >
      <b-overlay :show="loading">
        <div>
          <from-menu
            :type="type"
            :data="detail"
            @cancel="cancel"
            @submit="submit"
          />
        </div>
      </b-overlay>
    </b-modal>
  </div>
</template>
<script>
import {
  GET_FOOD_TEMPLATES,
  GET_MENU_DETAIL,
} from '@/core/services/store/menu.module';
import { createNamespacedHelpers } from 'vuex';
const { mapActions, mapState } = createNamespacedHelpers('menu');
export default {
  name: 'ModalMenu',
  components: {
    'from-menu': () => import('./Form.vue'),
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'create',
    },
  },
  data() {
    return {
      loading: false,
      detail: null,
      typeModal: null,
    };
  },
  computed: {
    ...mapState(['listdayfood']),
  },
  watch: {
    id: {
      immediate: true,
      deep: true,
      handler(newVal) {
        if (newVal != null) {
          this.loadData(newVal);
        }
      },
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    ...mapActions({ GET_FOOD_TEMPLATES, GET_MENU_DETAIL }),
    loadData() {
      if (this.id) {
        this.getMenuDetail();
      }
    },
    async getMenuDetail() {
      this.loading = true;
      const { data, error } = await this.GET_MENU_DETAIL({ id: this.id });
      if (!error) {
        this.detail = {
          ...data,
          status: 2,
          code: this.type !== 'copy' ? data.code : null,
        };
      }
      this.loading = false;
    },
    submit(params) {
      if (this.type === 'create') {
        return this.createMenuFood(params);
      }
      params['menuId'] = this.id;
      delete params.note;
      this.copyMenuFood(params);
    },
    async createMenuFood(params) {
      this.loading = true;
      try {
        let { data } = await this.$api.post('/FoodMenu', params);
        if (data.statusCode === 200) {
          this.$toastr.s({
            title: 'Thành công !',
            msg: `Tạo mới thành công!`,
          });
          return this.$router.push({
            name: 'menu_edit',
            params: {
              id: data.id,
            },
          });
        }

        this.$toastr.e({
          title: 'Lỗi !',
          msg: data.message,
        });
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
        });
      } finally {
        this.loading = false;
      }
    },
    async copyMenuFood(params) {
      this.loading = true;
      try {
        let res = await this.$api.put('/FoodMenu/Input/copy', params);
        if (res.statusCode === 200) {
          this.$emit('submit');
          return this.$router.push({
            name: 'menu_edit',
            params: {
              id: res.id,
            },
          });
        }

        this.$toastr.e({
          title: 'Lỗi !',
          msg: res.message,
        });
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
        });
      } finally {
        this.loading = false;
      }
    },
    async updateMenuFood(params) {
      this.loading = true;
      try {
        let { meta, data } = await this.$api.put('/FoodMenu/Input', params);
        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: data.message,
          });
        }
        return this.$emit('submit');
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
        });
      } finally {
        this.loading = false;
      }
    },
    updateData() {
      this.$emit('load-data');
    },
    cancel() {
      this.$emit('cancel');
    },
    edit() {
      this.$emit('edit');
    },
  },
};
</script>
<style lang="scss">
#menu-modal {
  .modal-dialog {
    max-width: 70%;
  }
}
.aterisk {
  color: red;
}
</style>
